import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/_shared/project.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  lengthprojects:any;
  lengthprojectsdisplayed:any;
  constructor(private projectService:ProjectService) { }

  ngOnInit(): void {
    this.projectService.get_actualités_list_count().subscribe(data => {
      this.lengthprojects = data;
      if(!this.lengthprojects){
        this.lengthprojects = 0
      }
    });
    this.projectService.get_actualités_list_count_displayed().subscribe(data => {
      this.lengthprojectsdisplayed = data;
      if(!this.lengthprojectsdisplayed){
        this.lengthprojectsdisplayed = 0
      }
    });
  }

}
