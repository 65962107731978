<a  class="btn w-full btn-primary text-truncate rounded-0 py-2 border-0 position-relative" style="z-index: 1000;background: #56958e !important;">
  <strong style="color: #fff !important;">Vetcorner</strong> 
  <i class="bi bi-box-arrow-left" title="Déconnexion" style="position: absolute;right: 50px;font-size: 25px;color: white;top: 2px;" (click)="logout()"></i>
</a>
<!-- Dashboard -->
<div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
  <!-- Vertical Navbar -->
  <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
      <div class="container-fluid">
          <!-- Toggler -->
          <button (click)="changenavbarclass()" class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Brand -->
          <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin">
              <img src="assets/images/logo.png" alt="...">
          </a>
          <div class="navbar-user d-lg-none">
              <!-- Dropdown -->
              <div class="dropdown">
                  <!-- Toggle -->
                  <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="avatar-parent-child">
                          <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                  </a>
                  <!-- Menu -->
              </div>
          </div>
          <!-- Collapse -->
          <div  [ngClass]="navbarclass"  id="sidebarCollapse">
              <!-- Navigation -->
              <ul class="navbar-nav">
                  <li class="nav-item">
                      <a class="nav-link" routerLink="/admin">
                          <i class="bi bi-house"></i> Tableau de bord
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" routerLink="actualites">
                          <i class="bi bi-newspaper"></i> Articles
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
  <!-- Main content -->
  
  <div class="h-screen flex-grow-1">
     
 <router-outlet></router-outlet>
 
 </div>
 
</div>