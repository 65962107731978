<br><br><br><br><br><br>
<div class="container" id="container">
 
  <div class="form-container sign-in-container">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <h1>Connexion</h1>
      <div class="social-container">
        <a href="/" class="social"><i class="fas fa-circle" style="color: #c7e0de;"></i></a>
        <a href="/" class="social"><i class="fas fa-circle" style="color: #54948d;"></i></a>
        <a href="/" class="social"><i class="fas fa-circle" style="color: #344e5e;"></i></a>
      </div>
      <span class="span"><b>* Tous les champs sont obligatoires</b></span>
      <input placeholder="Email"
      type="email"
      formControlName="email"
     
      class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
    />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Veuillez renseigner ce champ.</div>
      <div *ngIf="f.email.errors.email">
        Merci de saisir une adresse email valide.
      </div>
    </div>

    <input placeholder="Mot de passe"
    type="password"
    formControlName="password"
    class="form-control"
    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
  />
  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
    <div *ngIf="f.password.errors.required">Veuillez renseigner ce champ.</div>
    
  </div>
  <div style="    width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;" *ngIf="eroorMessage">{{eroorMessage}}</div>
    
      <br>
      <button>Connexion</button>
      <br>
     
    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">
    
      <div class="overlay-panel overlay-right">
        <img class="img-fluid" src="assets/images/logo.png" alt="" width="150px" height="150px">
        <h1 style="margin-top: 26px">Bonjour, cher utilisateur</h1>
        <p>Bienvenue dans notre platforme VetCorner</p>
        <!-- <button class="ghost" id="signUp">Sign Up</button> -->
      </div>
    </div>
  </div>
</div>

