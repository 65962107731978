import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-templ-admin',
  templateUrl: './templ-admin.component.html',
  styleUrls: ['./templ-admin.component.css']
})
export class TemplAdminComponent implements OnInit {
  currentUser: User;
  navbarclass = "collapse navbar-collapse";
 
  constructor(private authentificationService :AuthenticationService, private token :TokenStorageService) { 
    this.authentificationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    )
  }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
  }

  logout()
  {
    this.authentificationService.logout()
  }
  
  changenavbarclass(){
    if(this.navbarclass.indexOf("show") == -1 ){
      this.navbarclass = "collapse navbar-collapse show";
    }else{
      this.navbarclass = "collapse navbar-collapse";
    }
  }

}
