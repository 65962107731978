import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest,HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment'
import { title } from 'process';
const baseUrl = environment.baseUrl + '/pagesCMS/';
const baseUrlPage = environment.baseUrl + '/pagesCMS';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private http: HttpClient) {
  }
  check_actualite_existance(title){
    return this.http.get(`${baseUrlPage}/checktitle-verification/`+title);
  }
  check_Url_actualite_existance(title){
    return this.http.get(`${baseUrlPage}/checkUrl-fromtitle-verification/`+title);
  }

  check_actualite_existanceupadte(title, id){
    return this.http.get(`${baseUrlPage}/checktitle-verification-update/`+title+`/`+id);
  }

  check_Urlactualite_existanceForupadte(title, id){
    return this.http.get(`${baseUrlPage}/checkurl-fromTitle-verification-update/`+title+`/`+id);
  }

  deleteImage(image, UUId){
    return this.http.get(`${baseUrlPage}/projects/delete-image/`+UUId+`/`+image);
  }
  uploadBackground(forma, myUUID){
    const req = new HttpRequest('POST', `${baseUrlPage}/actualites/upload-background/`+myUUID, forma, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
​
  }

  createfolder(myUUID){
    return this.http.post(`${baseUrlPage}/crearte-folder/`+myUUID, myUUID);
  }

  get_all_contents() {
    return this.http.get(`${baseUrl}/all`);
  }

  get_project_list() {
    return this.http.get(`${baseUrlPage}projects`);
  }

  get_project_visible_not_deleted_list() {
    return this.http.get(`${baseUrlPage}/projects/visiblenotdeleted`);
  }

  get_actualités_list() {
    return this.http.get(`${baseUrlPage}/actualites`);
  }
  get_actualités_valid_list() {
    return this.http.get(`${baseUrlPage}/actualites-valid`);
  }

  get_actualités_hidden_list() {
    return this.http.get(`${baseUrlPage}/actualites-hidden`);
  }

  get_actualités_deleted_list() {
    return this.http.get(`${baseUrlPage}/actualites-deleted`);
  }


  get_actualités_list_count() {
    return this.http.get(`${baseUrlPage}/actualites/count`);
  }

  get_actualités_list_count_displayed() {
    return this.http.get(`${baseUrlPage}/actualites/count/displayed`);
  }

  get_actualités_list_with_contents() {
    return this.http.get(`${baseUrlPage}/actualitesWithContents`);
  }
  

  get_actualités_visible_not_deleted_list() {
    return this.http.get(`${baseUrlPage}/actualites/visiblenotdeleted`);
  }

  get_project_list_client() {
    return this.http.get(`${baseUrlPage}projects/client`);
  }

  get_project_by_id(projectId) {
    return this.http.get(`${baseUrlPage}/project/${projectId}`);
  }
  get_project_by_name(name) {
    return this.http.get(`${baseUrlPage}/project/name/${encodeURIComponent(name)}`);
  }
  get_act_by_name(name) {
    return this.http.get(`${baseUrlPage}/actualite/name/${encodeURIComponent(name)}`);
  }
  get_all_marque() {
    return this.http.get(`${baseUrlPage}marques/all`);
  }

  update_project(project) {
    return this.http.put(`${baseUrlPage}/project/update`, project, {responseType: 'json'});
  }

  updateDisplayProject(project) {
    return this.http.put(`${baseUrlPage}/project/${project._id}/change-display-status`, {hide_page: !project.hide_page});
  }

  update_porject_image_and_url(project) {
    return this.http.put(`${baseUrlPage}/project/${project._id}/change-img-and-url`, project);
  }

  update_actualite_image_and_url(actualite) {
    return this.http.put(`${baseUrlPage}/actualite/${actualite._id}/change-img-and-url`, actualite);
  }
  

  add_project(project) {
    return this.http.post(`${baseUrlPage}/projects/add`, project, {responseType: 'json'}
    );
  }

  add_actualités(project) {
    return this.http.post(`${baseUrlPage}/actualites/add`, project, {responseType: 'json'}
    );
  }

  get_page_content(pageName: string) {
    return this.http.get<any>(`${baseUrlPage}` + pageName);
  }

  delete_project(project) {
    return this.http.put(`${baseUrlPage}/project/${project._id}/delete`, {deleted_page: !project.deleted_page});
  }
}



























