import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  loading = false;
  error :boolean = false;
  eroorMessage :string;
  AuthError :boolean =false;
  closeResult: string;
  


  constructor(private formBuilder: FormBuilder,
    private router :Router,private autenticationService :AuthenticationService
    ,private toastr: ToastrService,
    private tokenStorage :TokenStorageService
  
    ) { }

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
          
         
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required]],
      }, {
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.AuthError =false;
    this.eroorMessage = ""
    let role; 
      this.submitted = true;
     
      if (this.registerForm.invalid) {
          return;
      }
    
      this.autenticationService.loginUser(this.registerForm.value).subscribe(
        (data) => {
          
        if(data &&  data['success']){
          role =data['resultat'][0].role;
          localStorage.setItem('token',data['resultat'][1])
          localStorage.setItem('user',data['resultat'][0]._id)
          if(role == "admin")
          {
            this.router.navigate(['/admin'])
            this.tokenStorage.saveUser(data['resultat']);
          }
        }
        if(data &&  !data['success']){
          this.eroorMessage=data['message'];
          return false;
        }
           
        },
        (error) => {
          this.loading = false;
          this.eroorMessage="Merci de vérifier vos informations"
          return false;
        }

      );
  }
go()
{
  this.registerForm.reset();
}
  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }    
}
