import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/_shared/project.service';
import {environment} from '../../../../../environments/environment'

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  projectslist: any;
  showDeletedProject = false;
  projectToDelete: any;
  choosedProject:any;
  AllActive=false;
  AllPublished=true;
  AllHidden=false;
  AllDeleted=false;

  DeleteAction=false;
  HideAction=false;
  DisplayAction=false;
  RecoverAction = false;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/actualite/';

  constructor(private projectService: ProjectService, private toastr: ToastrService, private router: Router) {
  }

  get_projects_list() {
    this.projectService.get_actualités_valid_list().subscribe(data => {
      this.projectslist = data;
    });
  }
  get_valid_projects_list() {
    this.projectService.get_actualités_valid_list().subscribe(data => {
      this.projectslist = data;
    });
  }
  get_masqued_projects_list() {
    this.projectService.get_actualités_hidden_list().subscribe(data => {
      this.projectslist = data;
    });
  }
  get_deleted_projects_list() {
    this.projectService.get_actualités_deleted_list().subscribe(data => {
      this.projectslist = data;
    });
  }

  ngOnInit(): void {
    this.get_projects_list();
  }

  updateDisplayProject(project) {
    this.projectService.updateDisplayProject(project).subscribe(data => {
      this.toastr.success('L\'article a été modifié avec succès!');
      this.get_projects_list();
    });
  }


  // deleteDisplayProject() {
  //   this.projectToDelete.deleted_page = !this.projectToDelete.deleted_page;
  //   this.projectService.delete_project(this.projectToDelete._id).subscribe(data => {
  //     this.toastr.success('L\'actualité a été supprimer avec succès!');
  //     this.get_projects_list();
  //   });
  // }

  displayingDeletedProjects() {
    this.showDeletedProject = !this.showDeletedProject;
  }
  hideActialite(project){
    project = this.choosedProject
    this.projectService.updateDisplayProject(project).subscribe(data =>{
      if(project.hide_page == false){
        this.toastr.success('L\'article a été masqué avec succès!');
        this.HideAction =false;
        this.DisplayAction =false;
        this.GetAll()
      }else{
        this.toastr.success('L\'article est affiché avec succès!');
        this.HideAction =false;
        this.DisplayAction =false;
        this.GetAll()

      }
      this.get_projects_list();
    })
  }

  deleteActialite(project){
    this.DeleteAction = true;
    project = this.choosedProject
    this.projectService.delete_project(project).subscribe((data) =>{
      if(project.deleted_page == false){
        this.DeleteAction = false;
        this.RecoverAction = false;

      this.toastr.success('L\'article a été supprimé avec succès!');
      this.GetAll();
      }else{
        this.DeleteAction = false;
        this.RecoverAction = false;

        this.toastr.success('L\'article est récupéré avec succès!');
        this.GetAll();
      }
      this.get_projects_list();
    },(err)=>{
      this.toastr.error('Une  erreur est  servenue  lors de la  suppression de l\'article!')
    })
  }
  setObjectTotrait(project){
    this.choosedProject = project
  }
  UpdatePage(project_id){
    this.router.navigate(['admin/actualites/update/'+project_id]);
  }
  GetAll(){
    this.AllActive = false;
    this.AllDeleted = false;
    this.AllHidden=false;
    this.AllPublished =true;
this.get_projects_list();
  }
  GetPublished(){
    this.AllActive = false;
    this.AllDeleted = false;
    this.AllHidden=false;
    this.AllPublished =true;
    this.get_valid_projects_list()
  }
  GetHidden(){
    this.AllActive = false;
    this.AllDeleted = false;
    this.AllHidden=true;
    this.AllPublished =false;
    this.get_masqued_projects_list()
  }
  GetDeleted(){
    this.AllActive = false;
    this.AllDeleted = true;
    this.AllHidden=false;
    this.AllPublished =false;
    this.get_deleted_projects_list()
  }
  setDeleteMessage(project){
    if(project.deleted_page){
      this.DeleteAction =false;
      this.RecoverAction =true;

    }else{
      this.DeleteAction =true;
      this.RecoverAction =false;
    }
  }
  setHideMessage(project){
    if(project.hide_page){
      this.HideAction =false;
      this.DisplayAction =true;

    }else{
      this.HideAction =true;
      this.DisplayAction =false;
    }
  }

  Annuler(){
    this.DeleteAction = false;
    this.HideAction = false;
    this.DisplayAction = false;
    this.RecoverAction = false;
  }

}
