import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  currentUser
  d
  user
  role
  constructor(private authenticationService: AuthenticationService , private router :Router,private userService : UserService) { }

  canActivate() :boolean {
    if(!this.authenticationService.loggedIn() ){
    return true
   }
   else
   {
    // this.router.navigate(['/admin'])
    // return false
    this.currentUser = localStorage.getItem('user')
    this.d=this.userService.getUserrById(this.currentUser).subscribe(async data=>{
      this.user=data
      this.role=this.user.role
      if(this.role == "admin")
      {
        this.router.navigate(['/admin'])
        return false
      }
     

   
  })
  
}
}

}