import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { find, pull } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {environment} from '../../../../../environments/environment'
import * as  uuid from 'uuid';

// import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { ProductsService } from 'src/app/_shared/products.service';
import { ProjectService } from 'src/app/_shared/project.service';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  Choosenlanguage:any;
  NetUrl:any;
  BackgroundImage:any;
  ActiveSection: any;
  ImageSection2:any;
  ImageSection3:any;
  ImageSection4:any;
  isActiveSection1:boolean=true;
  isActiveSection2:boolean=false;
  isActiveSection3:boolean=false;
  isActiveSection4:boolean=false;
  isFR:boolean=true;
  isNL:boolean=false;
  myUUID = uuid.v4();
  checked: boolean = false;
  checkedURL: boolean = false;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/media/actualite/';


  
  

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarHiddenButtons: [
      [

        'bold',
        'italic',
        
      ],
      [
        'textColor',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
     
    
};

  categories = [
    {id: 1, name: "NOUVEAUTÉS"},
    {id: 2, name: "PROJETS"},
    {id: 3, name: "SHOWROOM"},
    {id: 4, name: "ÉVÉNEMENTS"},
    {id: 5, name: "INFORMATIONS"},
    {id: 6, name: "PRODUITS"},
  ];

  fixedProjectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'couverture',
        title: ' ',
        section_name_nl: 'Dekking',
        title_nl: ' ',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrir'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Ontdekken'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 2',
        title: 'section 2',
        section_name_nl: 'sectie 2',
        title_nl: 'sectie 2',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 3',
        title: 'section 3',
        section_name_nl: 'sectie 3',
        title_nl: 'sectie 3',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 4',
        title: 'section 4',
        section_name_nl: 'sectie 4',
        title_nl: 'sectie 4',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              }
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              }
            ]
          }
        ]
      }
    ],
    page_url: '',
    page_name: '',
    page_name_nl: '',
    description: '',
    description_nl: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'actualités',
    category: 'NOUVEAUTÉS',
    background: '',
    public_key: this.myUUID,
  };
  projectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'couverture',
        title: '',
        section_name_nl: 'Dekking',
        title_nl: '',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrir'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Ontdekken'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 2',
        title: 'section 2',
        section_name_nl: 'Dekking 2',
        title_nl: 'Dekking 2',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 3',
        title: 'section 3',
        section_name_nl: 'Dekking 3',
        title_nl: 'Dekking 3',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'section 4',
        title: 'section 4',
        section_name_nl: 'Dekking 4',
        title_nl: 'Dekking 4',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          },
          {
            language: 'nl',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      }
    ],
    page_url: '',
    page_name: '',
    page_name_nl: '',
    description: '',
    description_nl: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'actualités',
    category: 'NOUVEAUTÉS',
    background: '',
    public_key: this.myUUID,
    
  };

  products: any;
  fixedProduct: any;
  searchproduct: string;
  id: any;
  ArrayImage :Array<any> = ['','','',''];

  @ViewChild('tagInput') tagInputRef: ElementRef;
  form: FormGroup;

  constructor(private fb: FormBuilder, private toastr: ToastrService, private productService: ProductsService, private actRoute: ActivatedRoute, private projectService: ProjectService, private router: Router) {
    this.id = this.actRoute.snapshot.paramMap.get('id');
    this.Choosenlanguage ='FR';
  }

  ngOnInit(): void {
    this.isFR =true;
    this.Choosenlanguage ='FR';
    this.ActiveSection = 1;
      this.fixedProduct = "";
      this.projectStructure.sections[1].hide = true;
      this.projectStructure.sections[2].hide = true;
      this.projectStructure.sections[3].hide = true;
    this.form = this.fb.group({
      tag: ['undefined'],
    });

  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.form.controls.tag.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag)) {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag);
    } else {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].splice(-1);
    }
  }

  


  addGalleryImg() {
    // @ts-ignore
    this.projectStructure.sections[3].contents[0].content[2].elements.push({img: ''});
  }
/**
 * CHANGE BACKGROUND IMAGE
 * @param input 
 */
  changeBackgroundSection(input) {

    // var reader2 = new FileReader();
    // reader2.readAsDataURL(input.files[0]);
    // reader2.onload = function (e) {
    //   var image = new Image();
    //   image.src = e.target.result as string;
    //   image.onload = function () {
    //     var height = image.width;
    //     var width = image.height;
    //     if (height > 100 || width > 100) {
    //       alert("Height and Width must not exceed 100px.");
    //       return false;
    //     }
    //     alert("Uploaded image has valid Height and Width.");
    //     return true;
    //   };
    // }


    if(input.files[0].size < 1*1024*1024)
    {
      var types = ["image/png", "image/gif", "image/jpeg"]
      if(types.indexOf(input.files[0].type) == -1){
        this.toastr.error('L\’image doit être de type png, jpg ou jpeg.')
        return;
      }
    if (input.files && input.files[0]) {
      this.projectStructure.background = input.files[0].name;
      this.ArrayImage[0] =input.files[0];
      // this.uploadBackground(input.files[0],input.files[0].name);

      // let data = {
      //   "filename": this.projectStructure.background
      // }
      
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const obj = e.target.result;
        this.BackgroundImage=obj
      };
      reader.readAsDataURL(input.files[0]);
      
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 1 Mo');
  }
/**
 * ADD IMAGE FOR  SECTIONS
 * @param input 
 * @param arrayElementIndex 
 */
  addImg(input, arrayElementIndex) {

    if(input.files[0].size < 1*1024*1024)
    {
      var types = ["image/png", "image/gif", "image/jpeg"]
      if(types.indexOf(input.files[0].type) == -1){
        this.toastr.error('L\’image doit être de type png, jpg ou jpeg.')
        return;
      }
    if (input.files && input.files[0]) {
      this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = input.files[0].name;
      // this.uploadBackground(input.files[0],input.files[0].name)
      const reader = new FileReader();
      reader.onload = (e: any) => {
      const obj = e.target.result;
      if(this.ActiveSection == 2){
        this.ArrayImage[1]=input.files[0];

        this.ImageSection2=obj;
      }else if(this.ActiveSection == 3 ){
        this.ArrayImage[2]=input.files[0];

        this.ImageSection3=obj;
      }else{
        this.ArrayImage[3]=input.files[0];

        this.ImageSection4=obj
      }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 1 Mo');
  }

  addProduct(productName: string) {
    this.searchproduct = '';
    // @ts-ignore
    this.projectStructure.sections[5].contents[0].content[1].elements.push({product: productName});

  }


  searchProduct() {
    this.products = this.fixedProduct.filter(x => {
      const name = x.s_modele_market.toLowerCase().toString();
      return name.includes(this.searchproduct.toLowerCase());
    });
  }

  replaceUrl(value, strToReplace,replacementStr ){
      if (!value || !strToReplace || !replacementStr) {
        var new_value = value.replace(/[^a-z0-9]/g, "_");
        return new_value;
      }
      var new_valuer = value.replace(/[^a-z0-9 ]/g, "_");
      return new_valuer.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }

  generateUrlPoject() {
    var newUrl  = this.replaceUrl(this.projectStructure.page_name.toLowerCase(),' ', '_');
    this.NetUrl = newUrl;
    this.projectStructure.page_url = '/articles/'+newUrl;
    this.projectStructure.page_url = (this.projectStructure.page_url[this.projectStructure.page_url.length - 1] == '_') ? this.projectStructure.page_url.slice(0, -1) : this.projectStructure.page_url;

  }
  checkExist(){
    this.projectService.check_actualite_existance(this.projectStructure.page_name).subscribe(resultat =>{
      if(resultat['message'] == "Exist"){
        this.checked =true;
      }
      else{
        this.checked= false;
      }
    })
  }

  checkUrlExist(){
    this.projectService.check_Url_actualite_existance(this.NetUrl).subscribe((checking)=>{
      if(checking['message'] == "Exist"){
        this.checkedURL =true;
      }
      else{
        this.checkedURL= false;
      }
    })
    
  }

  submit() {
    this.checkExist();   
    let validProject = true;
    if (this.projectStructure.page_name == '') {
      validProject = false;
      this.toastr.error('Le nom de l\'article en français  est  obligatoire.');
      return;
    }
    if(this.checked){
      validProject = false;
      this.toastr.error('Le nom de l\'article en français  est  déja utilisé.');
      return;
    }
    if(this.checkedURL){
      validProject = false;
      this.toastr.error('L\'Url de l\'article est conforme à une URL déja utilisé.');
      return;
    }
    
    if (this.projectStructure.page_name_nl == '') {
      validProject = false;
      this.toastr.error('Le nom de l\'article en néerlandais  est  obligatoire.');
      return;
    }
    if (this.projectStructure.page_url.length < 7) {
      validProject = false;
      this.toastr.error('L\'Url de page est court');
      return;
    }
    if (this.projectStructure.background == '') {
      validProject = false;
      this.toastr.error('L\'image de couverture est obligatoire');
      return;
    }
    
    if (validProject) {
      this.CreateFolder(this.myUUID);
      this.uploadBackground(this.ArrayImage);
      this.initname();
      this.projectStructure.description = this.projectStructure.sections[0].contents[0].content[1].elements[0]['description'];
      this.projectStructure.description_nl = this.projectStructure.sections[0].contents[1].content[1].elements[0]['description'];

      this.projectService.add_actualités(this.projectStructure).subscribe(data => {
        this.toastr.success('L\'article a été ajouté avec succès');
        this.projectStructure = this.fixedProjectStructure;
        setTimeout(() => {
         
          this.router.navigate(['/admin/actualites']).then(() => {
            
          });
        }, 1000);
      });
    }
  }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0]['title'] = this.projectStructure.page_name;
  }

  generateBannerTitleNL() {
    this.projectStructure.sections[0].title_nl = this.projectStructure.page_name_nl;
    this.projectStructure.sections[0].contents[1].content[0].elements[0]['title'] = this.projectStructure.page_name_nl;
  }

  navigateListProject() {
    this.router.navigate(['/admin/actualites']);
  }

  removeProduct(indexprd: number) {
    this.projectStructure.sections[5].contents[0].content[1].elements.splice(indexprd, 1);
  }

  uploadBackground(ArrayImage){
    for(let v in ArrayImage ){
      if((ArrayImage[v])){
        var name = "Section_"+v+".png";
        const newFile: File = new File([this.ArrayImage[v]], name, {type: this.ArrayImage[v].type});
        const formData: FormData = new FormData();
        formData.append('files', newFile);
  
      this.projectService.uploadBackground(formData, this.myUUID).subscribe(
        event => {
          //if (event.type === HttpEventType.UploadProgress) {
          //this.progressInfos[1].value = Math.round(100 * event.loaded / event.total);
          //} else 
          
        },
        err => {
          this.toastr.error('Les images ne peuvent pas être téléchargées, veuillez réessayer!',  ArrayImage[v].filename)
        });

      }      
    }
  }

  async CreateFolder(public_key){
    this.projectService.createfolder(public_key).subscribe((folder)=>{
      console.log("Folder Created ");
    })
  }

  initname(){
    this.projectStructure.background= "Section_0.png";
    // Section 1
    if(this.projectStructure.sections[1].contents[0].content[1].elements[0]['img']){
      this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "Section_1.png";

    }

    // Section 2
    if(this.projectStructure.sections[2].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "Section_2.png";

    }

    // Section 3
    if(this.projectStructure.sections[3].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "Section_3.png";
    }

  }

  language(langue){
    if(langue == 'FR'){
      this.isFR =true;
      this.isNL =false;
    }else{
      this.isFR =false;
      this.isNL =true;
      
    }
    this.Choosenlanguage = langue;
  }

  changesections(sectionName){
    if(sectionName == 1){
      this.isActiveSection2 =false;
      this.isActiveSection3 =false;
      this.isActiveSection4 =false;

      this.isActiveSection1 =true;

    }
    if(sectionName == 2){
      this.isActiveSection2 =true;
      this.isActiveSection3 =false;
      this.isActiveSection4 =false;
      this.isActiveSection1 =false;

    }
    if(sectionName == 3){
      this.isActiveSection2 =false;
      this.isActiveSection3 =true;
      this.isActiveSection4 =false;
      this.isActiveSection1 =false;

    }
    if(sectionName == 4){
      this.isActiveSection2 =false;
      this.isActiveSection3 =false;
      this.isActiveSection4 =true;
      this.isActiveSection1 =false;

    }
  this.ActiveSection =sectionName;
  }

  deleteImage(image){
    if(this.ActiveSection == 1){
      // this.DeletedBackgroundImage =true;
      this.fixedProjectStructure.background = "";
    this.projectStructure.background = "";
    this.BackgroundImage = this.BaseUrl+"default.png";
    }else
    {
       if(this.ActiveSection == 2){
        // this.DeletedSection2Image =true;
        this.ImageSection2 = this.BaseUrl+"default.png";
         this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "";
         this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0]['img'] = "";
      }else if(this.ActiveSection == 3){
        // this.DeletedSection3Image = true;
        this.ImageSection3 = this.BaseUrl+"default.png";
        this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "";
        this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]['img'] = "";
      }else{
        // this.DeletedSection4Image = true;
        this.ImageSection4 = this.BaseUrl+"default.png";
        this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "";
        this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]['img'] = "";
      }
    // }, (err) =>{
    //   this.toastr.error("Erreur lors de la suppression du fichier!")
    // })
  }
    
  }
  
}
