import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { TemplAdminComponent } from './admin/templ-admin/templ-admin.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginGuard } from './_guards/login.guard';
import { DashboardComponent } from './admin/templ-admin/dashboard/dashboard.component';
import { ListComponent as ListActualiteComponent} from './admin/templ-admin/actualite/list/list.component';
import { AddComponent as AddActualiteComponent} from './admin/templ-admin/actualite/add/add.component';
import { UpdateComponent as UpdateActualiteComponent} from './admin/templ-admin/actualite/update/update.component';






const routes: Routes = [
  { path: '', redirectTo: '/se_connecter', pathMatch: 'full'  , canActivate :[LoginGuard] },
  { path: 'se_connecter', component: LoginComponent },
  { path: 'admin', component: TemplAdminComponent , canActivate :[AuthGuard],   data: {role: 'admin'} ,
  children: [
    { path: '', component: DashboardComponent },
    { path: 'actualites', component: ListActualiteComponent },
    { path: 'actualites/add', component: AddActualiteComponent },
    { path: 'actualites/update/:id', component: UpdateActualiteComponent },
  ]
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
